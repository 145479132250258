


















import { Component, Vue } from 'vue-property-decorator';
import KusList from '@/kus/components/KuList.vue';
import KuFilter from '@/kus/components/KuFilter.vue';

@Component({
  components: {
    KusList,
    KuFilter,
  },
})
export default class Kus extends Vue {
  createGuidelinePopup = false;

  /**
   * Gets whether the user is authenticated
   */
  get authenticated(): boolean {
    return this.$store.state.authenticated;
  }

  /**
   * Returns a boolean if the guidelines are searching or not
   */
  get loading(): boolean {
    return this.$store.state.kuSearchResults.loading;
  }

}
