




















import { Component, Vue } from 'vue-property-decorator';
import GuidelineList from '@/guidelines/components/GuidelineList.vue';
import CreateGuidelineModal from '@/guidelines/components/CreateGuidelineModal.vue';
import GuidelineViewFilter from '@/guidelines/components/GuidelineViewFilter.vue';
import { Guideline } from '@/types/Guideline';

@Component({
  components: {
    GuidelineList,
    CreateGuidelineModal,
    GuidelineViewFilter,
  },
})
export default class Guidelines extends Vue {
  createGuidelinePopup = false;

  /**
   * Gets whether the user is authenticated
   */
  get authenticated(): boolean {
    return this.$store.state.authenticated;
  }

  /**
   * Returns a boolean if the guidelines are searching or not
   */
  get loading(): boolean {
    return this.$store.state.guidelineSearchResult.loading;
  }

  /**
   * Opens the create guideline modal
   */
  openCreateGuidelineModal(): void {
    this.createGuidelinePopup = true;
  }

  /**
   * Handles closing the create guideline modal, determines
   * whether we should send a request to create it
   * 
   * @param guideline optional guideline to create
   */
  async closeCreateGuidelineModal(guideline?: Guideline): Promise<void> {
    if (guideline) {
      await this.$store.dispatch('createGuideline', { guideline });
    }
    this.createGuidelinePopup = false;
  }
}
