
















import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Navbar,
    Footer,
  }
})
export default class App extends Vue {
  async mounted(): Promise<void> {
    await this.$store.dispatch('checkAuthenticated');
  }

  /**
   * Gets if there is an error from the application
   */
  get error(): string {
    return this.$store.state.error;
  }

  /**
   * Clears the error from the application
   */
  clearError(): void {
    this.$store.commit('setError', '');
  }
}
