import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Font Awesome Config
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisH, faAngleDown, faArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import and add to library here to use in vue
library.add(faEllipsisH)
library.add(faAngleDown)
library.add(faArchive)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
