


























































import { Component, Vue } from 'vue-property-decorator';
import { Guideline } from '@/types/Guideline';
import PopupConfirm from '@/components/PopupConfirm.vue';
import UpdateGuidelineModal from '@/guidelines/components/UpdateGuidelineModal.vue';
import Pagination from '@/components/Pagination.vue';
import { SearchItem } from '@/types/SearchItem';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    components: {
        PopupConfirm,
        UpdateGuidelineModal,
        Pagination,
    },
})
export default class GuidelineList extends Vue {
    // Searching Information
    text = '';
    searchInput$ = new Subject<void>();
    
    // Delete Popup Information
    deletePopup = false;
    selectedId = '';

    // Update Popup Information
    updatePopup = false;

    destroyed$: Subject<void> = new Subject();

    created(): void {
        this.$store.commit('setCurrentPage', 1);
        this.$store.commit('setTextQuery', undefined);
        this.$store.commit('setFilterFrameworkNames', []);
        this.$store.commit('setFilterDeprecated', 'all');
        this.searchInput$
            .pipe(debounceTime(650), takeUntil(this.destroyed$))
            .subscribe(async () => {
                this.$store.commit('setCurrentPage', 1);
                await this.searchGuidelines();
            });
    }

    mounted(): void {
        this.searchGuidelines();
    }

    destroyed(): void {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
    }

    /**
     * Gets whether the user is authenticated
     */
    get authenticated(): boolean {
        return this.$store.state.authenticated;
    }

    /**
     * Gets the guidelines array to display
     */
    get guidelines(): Guideline[] {
        return this.$store.state.guidelineSearchResult.results;
    }

    /**
     * Gets the total guidelines from the search
     */
    get total(): number {
        return this.$store.state.guidelineSearchResult.total;
    }

    /**
     * Gets the search limit
     */
    get limit(): number {
        return this.$store.state.filters.limit;
    }

    /**
     * Gets the total number of pages in the search
     */
    get totalPages(): number {
        return Math.ceil(this.total / this.limit);
    }

    /**
     * Sends a notification to the search input to
     * delay the time of search on
     */
    searchChanged(): void {
        this.searchInput$.next();
    }

    /**
     * Changes the page
     */
    async changePage(): Promise<void> {
        await this.searchGuidelines();
    }

    /**
     * Searches guidelines given a query object
     */
    async searchGuidelines(): Promise<void> {
        this.$store.commit('setTextQuery', this.text);
        await this.$store.dispatch('searchGuidelines');
    }

    /**
     * Opens the delete confirm popup
     */
    deleteGuideline(id: string): void {
        this.deletePopup = true;
        this.selectedId = id;
    }

    /**
     * Confirms if the user would like to delete the guideline or not
     * using the return value of the popup
     */
    async confirmDelete(confirmed: boolean): Promise<void> {
        if (confirmed) {
            await this.$store.dispatch('deleteGuideline', { id: this.selectedId });
        }

        this.deletePopup = false;
        this.selectedId = '';
    }

    /**
     * Opens the update guideline popup
     */
    async updateGuideline(guideline: SearchItem): Promise<void> {
        this.updatePopup = true;
        await (this.$refs.updateModal! as UpdateGuidelineModal).populateGuideline(guideline);
    }

    /**
     * Confirms if the guideline was updated
     */
    async confirmUpdate(guideline?: Guideline): Promise<void> {
        if (guideline) {
            await this.$store.dispatch('updateGuideline', { id: guideline._id, updates: guideline });
        }
        this.updatePopup = false;
    }
}
