















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CreateFrameworkeModal extends Vue {
    @Prop({type: Boolean, required: true})
    isActive!: boolean;

    error = false;

    // Framework form model
    framework = {
        name: '',
        author: '',
        source: '',
        description: '',
    };

    /**
     * Checks if all the fields have been filled out before submitting
     */
    isSubmitable() {
        return this.framework.name && this.framework.author && this.framework.source && this.framework.description;
    }

    /**
     * Clears the inputed form data
     */
    clearInput() {
        this.framework = {
            name: '',
            author: '',
            description: '',
            source: '',
        };
        this.error = false;
    }

    /**
     * Creates the guideline
     */
    async create(): Promise<void> {
        if (this.isSubmitable()) {
            this.$emit('close', this.framework);
            this.clearInput();
        } else {
            this.error = true;
        }
    }

    /**
     * Cancels the create guideline form
     */
    cancel(): void {
        this.clearInput();
        this.$emit('close');
    }
}
