















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Navbar extends Vue {
    navigateTo(path: string): void {
        this.$router.push(path);
    }

    checkIsActive(option: string): boolean {
        return this.$route.path.includes(option);
    }

    logout(): void {
        this.$store.dispatch('logout');
    }
}
