



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PopupConfirm extends Vue {
    @Prop({type: Boolean, required: true})
    isActive!: boolean;

    @Prop({type: String, required: true})
    message!: string;

    /**
     * Confirms that the user wants to delete the item
     */
    confirm(): void {
        this.$emit('confirm', true);
    }

    /**
     * Confirms that the user doesn't want to delete the item
     */
    cancel(): void {
        this.$emit('confirm', false);
    }
}
