























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {
    @Prop({type: Boolean, required: true})
    isActive!: boolean;

    @Prop({type: String, required: true})
    message!: string;

    email = '';
    password = '';

    /**
     * Confirms that the user wants to delete the item
     */
    async login(): Promise<void> {
        await this.$store.dispatch('login', {email: this.email, password: this.password})
        this.$emit('login', true);
    }

    /**
     * Confirms that the user doesn't want to delete the item
     */
    cancel(): void {
        this.$emit('login', false);
    }
}
