















































import { Framework } from '@/types/Framework';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class UpdateFrameworkModal extends Vue {
    @Prop({type: Boolean, required: true})
    isActive!: boolean;

    @Prop({type: Object, required: true})
    framework!: Framework;
    
    error = false;

    /**
     * Checks if all the fields have been filled out before submitting
     */
    isSubmitable() {
        return this.framework.name && this.framework.author && this.framework.source && this.framework.description;
    }

    /**
     * Clears the inputed form data
     */
    clearInput() {
        this.framework.name = '';
        this.framework.author = '';
        this.framework.source = '';
        this.framework.description = '';
        this.error = false;
    }

    /**
     * Creates the guideline
     */
    async update(): Promise<void> {
        if (this.isSubmitable()) {
            this.$emit('close', JSON.parse(JSON.stringify(this.framework)));
            this.clearInput();
        } else {
            this.error = true;
        }
    }

    /**
     * Cancels the create guideline form
     */
    cancel(): void {
        this.clearInput();
        this.$emit('close');
    }
}
