













import { Component, Vue } from 'vue-property-decorator';
import Login from '@/components/Login.vue';

@Component({
    components: {
        Login
    }
})
export default class Footer extends Vue {

    // Login popup flag
    loginPopup = false;

    login(): void {
        this.loginPopup = true;
    }

    togglePopup(): void {
        this.loginPopup = false;
    }
}
