


































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class KuFilter extends Vue {

  mounted(): void {
    this.getKus();
  }

  get foundationalKus(): string[] {
    return [
        "Cybersecurity Foundations",
        "IT System Components",
        "Cybersecurity Principles"
    ];
  }

  get technicalCoreKus(): string[] {
    return [
        "Operating Systems Concepts",
        "Network Defense",
        "Basic Scripting and Programming",
        "Basic Networking",
        "Basic Cryptography"
    ];
  }

  get nonTechnicalCoreKus(): string[] {
    return [
        "Cybersecurity Planning and Management",
        "Security Risk Analysis",
        "Security Program Management",
        "Policy, Legal, Ethics, and Compliance",
        "Cyber Threats"
    ];
  }

  get optionalKus(): string[] {
    return [
        "Advanced Algorithms",
        "Advanced Cryptography",
        "Advanced Network Technology and Protocols",
        "Algorithms",
        "Analog Telecommunications",
        "Basic Cyber Operations",
        "Cloud Computing", 
        "Cyber Crime",
        "Cybersecurity Ethics",
        "Data Administration",
        "Data Structures",
        "Database Management Systems",
        "Databases",
        "Device Forensics",
        "Digital Communications",
        "Digital Forensics",
        "Embedded Systems",
        "Forensic Accounting",
        "Formal Methods",
        "Fraud Prevention and Management",
        "Hardware Reverse Engineering",
        "Hardware/Firmware Security",
        "Host Forensics",
        "IA Architectures",
        "IA Compliance",
        "IA Standards",
        "Independent/Directed Study/Research",
        "Industrial Control Systems",
        "Introduction to Theory of Computation",
        "Intrusion Detection/Prevention Systems",
        "Life-Cycle Security",
        "Linux System Administration",
        "Low Level Programming",
        "Media Forensics",
        "Mobile Technologies",
        "Network Forensics",
        "Network Security Administration",
        "Network Technology and Protocols",
        "Operating System Administration",
        "Operating Systems Hardening",
        "Operating Systems Theory",
        "Penetration Testing",
        "Privacy",
        "QA/Functional Testing",
        "Radio Frequency Principles",
        "Secure Programming Practices",
        "Software Assurance",
        "Software Reverse Engineering",
        "Software Security Analysis",
        "Supply Chain Security",
        "Systems Certification and Accreditation",
        "Systems Programming",
        "Systems Security Engineering",
        "Virtualization Technologies",
        "Vulnerability Analysis",
        "Web Application Security",
        "Windows System Administration",
        "Wireless Sensor Networks"
    ]
  }

  /**
   * Gets the selected KU names
   */
  get kuNames(): string[] {
    return this.$store.state.filters.kus as string[];
  }

  /**
   * Clears the selected KUs
   */
  clearFilter(): void {
    this.$store.commit('setKuFilter', []);
    this.$store.commit('setCurrentPage', 1);
    this.$store.dispatch('searchKus');
  }

  /**
   * Selects or deselects KUs 
   * 
   * @param name the name to select/deselect
   */
  selectKu(name: string): void {
    if (this.kuNames.includes(name)) {
      this.$store.commit('removeKuFilter', name);
    } else {
      this.$store.commit('addKuFilter', name)
    }
    this.$store.dispatch('searchKus');
  }

  /**
   * Searches frameworks to populate filter
   */
  private async getKus(): Promise<void> {
    await this.$store.dispatch('searchKus', { limit: 100, page: 1 });
  }
}
