



























import { Component, Vue } from 'vue-property-decorator';
import PopupConfirm from '@/components/PopupConfirm.vue';
import UpdateGuidelineModal from '@/guidelines/components/UpdateGuidelineModal.vue';
import Pagination from '@/components/Pagination.vue';
import { SearchItem } from '@/types/SearchItem';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    components: {
        PopupConfirm,
        UpdateGuidelineModal,
        Pagination,
    },
})
export default class KusList extends Vue {
    // Searching Information
    text = '';
    searchInput$ = new Subject<void>();

    destroyed$: Subject<void> = new Subject();

    created(): void {
        this.$store.commit('setCurrentPage', 1);
        this.$store.commit('setTextQuery', undefined);
        this.$store.commit('setKuFilter', []);
        this.searchInput$
            .pipe(debounceTime(650), takeUntil(this.destroyed$))
            .subscribe(async () => {
                this.$store.commit('setCurrentPage', 1);
                await this.searchKus();
            });
    }

    mounted(): void {
        this.searchKus();
    }

    destroyed(): void {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
    }

    /**
     * Gets whether the user is authenticated
     */
    get authenticated(): boolean {
        return this.$store.state.authenticated;
    }

    /**
     * Gets the guidelines array to display
     */
    get kus(): any[] {
        return this.$store.state.kuSearchResults.results;
    }

    /**
     * Gets the total guidelines from the search
     */
    get total(): number {
        return this.$store.state.kuSearchResults.total;
    }

    /**
     * Gets the search limit
     */
    get limit(): number {
        return this.$store.state.filters.limit;
    }

    /**
     * Gets the total number of pages in the search
     */
    get totalPages(): number {
        return Math.ceil(this.total / this.limit);
    }

    /**
     * Sends a notification to the search input to
     * delay the time of search on
     */
    searchChanged(): void {
        this.searchInput$.next();
    }

    /**
     * Changes the page
     */
    async changePage(): Promise<void> {
        await this.searchKus();
    }

    /**
     * Searches guidelines given a query object
     */
    async searchKus(): Promise<void> {
        this.$store.commit('setTextQuery', this.text);
        await this.$store.dispatch('searchKus');
    }

}
