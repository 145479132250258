import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Frameworks from '@/frameworks/views/Frameworks.vue'
import Guidelines from '@/guidelines/views/Guidelines.vue'
import Kus from '@/kus/views/Kus.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/frameworks',
  },
  {
    path: '/frameworks',
    name: 'frameworks',
    component: Frameworks,
  },
  {
    path: '/guidelines',
    name: 'guidelines',
    component: Guidelines,
  },
  {
    path: '/kus',
    name: 'CAE CDE KUs',
    component: Kus
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
