








































import { Framework } from '@/types/Framework';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GuidelineViewFilter extends Vue {

  // Dropdown variables
  indexHover = false;
  indexOptions = [
    { name: 'Guidelines V1', value: 'Guidelines' },
    { name: 'Guidelines V2 (Beta)', value: 'GuidelinesV2' },
    { name: 'KSA KU Mappings (Beta)', value: 'ksaKus'}
  ];

  selectedDeprecatedStatus: string[] = [];

  mounted(): void {
    this.getFrameworks();
  }

  /**
   * Gets the selected guideline search index from the filter state
   */
  get selectedIndex(): string {
    return this.$store.state.filters.index;
  }

  /**
   * Sets the selected guideline search index
   */
  set selectedIndex(index: string) {
    this.$store.commit('setSearchIndex', index);
    this.$store.dispatch('searchGuidelines');
  }

  /**
   * Gets the sorted frameworks for filtering
   */
  get frameworks(): Framework[] {
    return this.$store.state.frameworkSearchResult.results.sort((a: Framework, b: Framework) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      if (a.name === b.name) return 0;
    });
  }

  /**
   * Gets the selected framework names
   */
  get frameworkNames(): string[] {
    return this.$store.state.filters.frameworkNames;
  }
  /**
   * Gets the selected deprecated frameworks
   */
  get deprecated(): string {
    return this.$store.state.filters.deprecated;
  }

  setDeprecated(val: string): void {
    this.$store.commit('setFilterDeprecated', val)
  }

  /**
   * Clears the selected framework names
   */
  clearFilter(): void {
    this.$store.commit('setFilterFrameworkNames', []);
    this.$store.commit('setCurrentPage', 1);
    this.$store.dispatch('searchGuidelines');
    this.$store.commit('setFilterDeprecated', 'all');
  }

  /**
   * Selects or deselects frameworks
   * 
   * @param name the name to select/deselect
   */
  selectFramework(name: string): void {
    if (this.frameworkNames.includes(name)) {
      this.$store.commit('removeFilterFrameworkName', name);
    } else {
      this.$store.commit('addFilterFrameworkName', name)
    }
    this.$store.dispatch('searchGuidelines');
  }

  /**
   * Selects or deselects a deprecated framework
   * 
   * @param deprecated the name to select/deselect
   */
   selectDeprecate(deprecated: string): void {
    if (this.selectedDeprecatedStatus.includes(deprecated)) { 
      this.selectedDeprecatedStatus.splice(this.selectedDeprecatedStatus.indexOf(deprecated), 1);
    } else {
      this.selectedDeprecatedStatus.push(deprecated);   //push deprecated status
    }
    // Switch statement based on if deprecatedStatus is set to deprecated (0) in the array
    switch(this.selectedDeprecatedStatus.length) {
      case 1:
        this.setDeprecated(this.selectedDeprecatedStatus[0]);
        break;
      default:
        this.setDeprecated('all');
        break;
    }
    this.$store.dispatch('searchGuidelines');
  }
  /**
   * Searches frameworks to populate filter
   */
  private async getFrameworks(): Promise<void> {
    await this.$store.dispatch('searchFrameworks', { limit: 100, page: 1 });
  }
}
