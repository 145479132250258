

















import { Component, Vue } from 'vue-property-decorator';
import FrameworkList from '@/frameworks/components/FrameworkList.vue';
import CreateFrameworkModal from '@/frameworks/components/CreateFrameworkModal.vue';
import { Framework } from '@/types/Framework';

@Component({
  components: {
    FrameworkList,
    CreateFrameworkModal,
  },
})
export default class Frameworks extends Vue {
  createFrameworkPopup = false;

  /**
   * Checks if the user is authenticated
   */
  get authenticated(): boolean {
    return this.$store.state.authenticated;
  }

  /**
   * Returns a boolean if the frameworks are searching or not
   */
  get loading(): boolean {
    return this.$store.state.frameworkSearchResult.loading;
  }

  /**
   * Opens the create framework popup modal
   */
  openCreateFrameworkPopup() {
    this.createFrameworkPopup = true;
  }

  /**
   * Closes the create framework popup modal, if there was a
   * object returned, create a new framework
   * 
   * @param framework The framework to create
   */
  async closeCreateFrameworkPopup(framework?: Framework) {
    if (framework) {
      await this.$store.dispatch('createFramework', { framework });
    }
    this.createFrameworkPopup = false;
  }
}
