



















































































import { Framework } from '@/types/Framework';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Guideline } from '@/types/Guideline';
import { SearchItem } from '@/types/SearchItem';

@Component({})
export default class UpdateGuidelineModal extends Vue {
    @Prop({type: Boolean, required: true})
    isActive!: boolean;

    destroyed$: Subject<void> = new Subject();

    error = false;

    years: string[] = [];
    levels: string[] = ['elementary', 'middle', 'high', 'undergraduate', 'graduate',
        'post graduate', 'community college', 'professional development', 'grade k-1',
        'grade 2-3', 'grade 4-5', 'grade 6-8', 'grade 9-12', 'grade 10-12'];
    
    // Form Data
    _id = '';
    name = '';
    description = '';
    year = '';
    isGuideline = true;
    selectedLevels: string[] = [];
    selectedFramework: Framework = {
        name: '',
        author: '',
        description: '',
        source: ''
    };

    // Framework search
    search = '';
    searching = false;
    frameworkSearchInput$: Subject<void> = new Subject();

    get framework(): Framework {
        return this.$store.state.framework;
    }

    get guideline(): Guideline {
        return this.$store.state.guideline;
    }

    /**
     * Returns all frameworks except the currently
     * selected framework
     */
    get frameworks(): Framework[] {
        return this.$store.state.frameworkSearchResult.results
            .filter((framework: Framework) => {
                return framework.name !== this.selectedFramework.name
                    && framework.author !== this.selectedFramework.author
                    && framework.source !== this.selectedFramework.source
                    && framework.description !== this.selectedFramework.description;
            });
    }

    created(): void {
        // Populate valid guideline/standard years
        const current = new Date().getFullYear();
        const oldest = 2010;
        for (let i = current; i >= oldest; i--) {
            this.years.push(i.toString());
        }

        // Setup search debounce time
        this.frameworkSearchInput$
            .pipe(debounceTime(650), takeUntil(this.destroyed$))
            .subscribe(async () => {
                this.searching = false;
                await this.searchFrameworks();

                // If there are no frameworks after the search and the search query is empty we aren't searching
                if (this.frameworks.length === 0 && this.search === '') {
                    this.searching = false;
                } else {
                    this.searching = true;
                }
            });
    }

    destroyed(): void {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
    }

    /**
     * Populates the editable fields with the selected search item (sent from parent)
     * 
     * @param item The search item to edit
     */
    async populateGuideline(item: SearchItem) {
        await this.$store.dispatch('getGuidelineById', { id: item.guidelineId });
        if (this.guideline) {
            await this.$store.dispatch('getFrameworkById', { id: this.guideline.frameworkId });

            // Set Guideline Values
            this._id = this.guideline._id!;
            this.name = this.guideline.name;
            this.description = this.guideline.description || this.guideline.guideline || '';
            this.year = this.guideline.year;
            this.selectedLevels = this.guideline.levels;
            this.isGuideline = this.guideline.guideline !== undefined;

            // Set Framework
            if (this.framework) {
                this.selectedFramework = this.framework;
            }
        }
    }

    /**
     * Sets if the guideline/standard is a guideline or standard
     * 
     * @param val true if guideline, false if standard
     */
    setIsGuideline(val: boolean): void {
        this.isGuideline = val;
    }

    /**
     * Adds a level to the selected levels array if it doesn't exist
     * already
     * 
     * @param level the level to add
     */
    addLevel(level: string): void {
        if (!this.selectedLevels.includes(level)) {
            this.selectedLevels.push(level);
        }
    }

    /**
     * Removes a level from the selected levels array if it exists
     * 
     * @param level the level to remove
     */
    removeLevel(level: string): void {
        const index = this.selectedLevels.indexOf(level);
        if (index > -1) {
            this.selectedLevels.splice(index, 1);
        }
    }

    /**
     * Selects a framework from the search dropdown, clears
     * searching
     * 
     * @param framework the framework selected
     */
    selectFramework(framework: Framework): void {
        this.selectedFramework = framework;
        this.search = '';
        this.searching = false;
    }

    /**
     * Removes the selected framework option
     */
    removeFramework(): void {
        this.selectedFramework = {
            name: '',
            author: '',
            description: '',
            source: ''
        };
    }

    /**
     * Checks that the selected framework is not the
     * empty base framework
     */
    selectedFrameworkIsDefined() {
        return this.selectedFramework.name && this.selectedFramework.author && this.selectedFramework.description && this.selectedFramework.source;
    }

    /**
     * Sends a notification to the search input to
     * delay the time of search on
     */
    searchChanged(): void {
        this.frameworkSearchInput$.next();
    }

    /**
     * Sends a search request to the state using the text search
     */
    async searchFrameworks(): Promise<void> {
        await this.$store.dispatch('searchFrameworks', { text: this.search });
    }

    /**
     * Checks if the guideline is submittable
     */
    isSubmittable() {
        return this.name && this.year && this.description && this.levels.length > 0 && this.selectedFrameworkIsDefined();
    }

    /**
     * Clears the input of the guideline
     */
    clearInput(): void {
        this.name = '';
        this.year = '';
        this.description = '';
        this.selectedLevels = [];
        this.removeFramework();
        this.error = false;
    }

    /**
     * Creates the guideline
     */
    update(): void {
        if (this.isSubmittable()) {
            const guideline: Guideline = {
                _id: this._id,
                name: this.name,
                year: this.year,
                levels: this.selectedLevels,
                frameworkId: this.selectedFramework._id!,
            };
            if (this.isGuideline) {
                guideline.guideline = this.description;
            } else {
                guideline.description = this.description;
            }

            this.$emit('close', JSON.parse(JSON.stringify(guideline)));
            this.clearInput();
        } else {
            this.error = true;
        }
    }

    /**
     * Cancels the create guideline form
     */
    cancel(): void {
        this.clearInput();
        this.$emit('close');
    }
}
