

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
    @Prop({ required: true })
    lastPage!: number;

    get currentPage(): number {
        return this.$store.state.filters.page;
    }

    set currentPage(page: number) {
        this.$store.commit('setCurrentPage', page);
    }

    /**
     * Registers the page has been changed
     *
     * @param newPage the new page selected
     */
    changePage(newPage: number): void {
        if (newPage !== this.currentPage) {
            this.currentPage = newPage;
            this.$emit('pageChange');
        }
    }

    /**
     * Gets the pages to display in the UI
     */
    getPages(): (Page | Ellipse)[] {
        const tempPages = this.createTempPages();
        const uniquePages = this.createUniquePages(tempPages);
        return this.addEllipses(uniquePages);
    }

    /**
     * Creates all the temporary pages, regardless if they are duplicate
     * pages, and removes the invalid page numbers (< 1 or > last page number)
     */
    private createTempPages(): Page[] {
        let pages = [
            { pageNum: 1, active: this.currentPage === 1 },
            { pageNum: this.currentPage - 1, active: false },
            { pageNum: this.currentPage, active: true},
            { pageNum: this.currentPage + 1, active: false },
            { pageNum: this.lastPage, active: this.currentPage === this.lastPage},
        ];
        pages = pages.filter(page => page.pageNum >= 1 && page.pageNum <= this.lastPage);
        return pages;
    }

    /**
     * Removes duplicate page numbers, forming unique pages
     * 
     * @param tempPages array of pages to parse out for duplicates
     */
    private createUniquePages(tempPages: Page[]): Page[] {
        const pages: Page[] = [];
        for (let i = 0; i < tempPages.length; i++) {
            if (pages.findIndex(page => page.pageNum === tempPages[i].pageNum) < 0) {
                pages.push(tempPages[i]);
            }
        }
        return pages;
    }

    /**
     * Takes a unique page array and adds ellipses between pages
     * if applicable (ex, last page > current page + 2)
     * 
     * @param uniquePages array of unique pages to add ellipses to
     */
    private addEllipses(uniquePages: Page[]): (Page | Ellipse)[] {
        const pages: (Page | Ellipse)[] = [];
        for (let i = 0; i < uniquePages.length; i++) {
            pages.push(uniquePages[i]);
            if (i + 1 < uniquePages.length && uniquePages[i].pageNum + 1 !== uniquePages[i + 1].pageNum) {
                pages.push({ ellipse: true });
            }
        }
        return pages;
    }
}

interface Page {
    pageNum: number;
    active: boolean;
}

interface Ellipse {
    ellipse: boolean;
}
