import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import querystring from 'querystring';
import { Guideline } from '@/types/Guideline';
import { Framework } from '@/types/Framework';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    token: undefined,
    authenticated: false,
    error: '',
    guidelineSearchResult: {
      results: [],
      total: 0,
      loading: false,
    },
    guideline: undefined,
    frameworkSearchResult: {
      results: [],
      total: 0,
      loading: false,
    },
    framework: undefined,
    filters: {
      frameworkNames: [],
      text: undefined,
      kus: [],
      page: 1,
      limit: 20,
      deprecated: 'all',
      index: 'Guidelines',
    },
    ku: undefined,
    kuSearchResults: {
      results: [],
      total: 0,
      loading: false
    },
  },
  mutations: {
    setToken(state) {
      state.token = undefined;
      state.authenticated = false;

      // Decodes cookies
      const name = 'sg-presence';
      const decodedCookies = decodeURIComponent(document.cookie);
      const cookies = decodedCookies.split(';');

      // Checks each cookie for our cookie name
      cookies.forEach(cookie => {
        const index = cookie.indexOf(name);
        if (index > -1) {
          // Sets cookie to state if found
          const token = cookie.substring(index + name.length + 1);
          state.token = { headers: { Authorization: `Bearer ${token}` }} as any;
        }
      });

      state.authenticated = state.token !== undefined;
    },
    deleteToken(state) {
      state.token = undefined;
      state.authenticated = false;

      document.cookie = 'sg-presence= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },
    setError(state, message) {
      state.error = message;
    },
    setGuidelineSearchResult(state, searchResults) {
      state.guidelineSearchResult = searchResults;
      state.guidelineSearchResult.loading = false;
    },
    setGuidelineSearchLoading(state, loading) {
      state.guidelineSearchResult.loading = loading;
    },
    setGuideline(state, guideline) {
      state.guideline = guideline;
    },
    setFrameworkSearchResult(state, searchResults) {
      state.frameworkSearchResult = searchResults;
      state.frameworkSearchResult.loading = false;
    },
    setFrameworkSearchLoading(state, loading) {
      state.frameworkSearchResult.loading = loading;
    },
    setFramework(state, framework) {
      state.framework = framework;
    },
    setFilterFrameworkNames(state, frameworkNames) {
      state.filters.frameworkNames = frameworkNames;
    },
    addFilterFrameworkName(state, frameworkName) {
      const index = (state.filters.frameworkNames as string[]).indexOf(frameworkName);
      if (index < 0) {
        (state.filters.frameworkNames as string[]).push(frameworkName);
      }
    },
    removeFilterFrameworkName(state, frameworkName) {
      const index = (state.filters.frameworkNames as string[]).indexOf(frameworkName);
      if (index >= 0) {
        state.filters.frameworkNames.splice(index, 1);
      }
    },
    setFilterDeprecated(state, deprecated) {
      state.filters.deprecated = deprecated;
    },
    setKu(state, ku) {
      state.ku = ku;
    },
    setKuFilter(state, kus) {
      state.filters.kus = kus;
    },
    addKuFilter(state, kuName) {
      const index = (state.filters.kus as string[]).indexOf(kuName);
      if (index < 0) {
        (state.filters.kus as string[]).push(kuName);
      }
    },
    removeKuFilter(state, kuName) {
      const index = (state.filters.kus as string[]).indexOf(kuName);
      if (index >= 0) {
        state.filters.kus.splice(index, 1);
      }
    },
    setKuSearchLoading(state, loading) {
      state.kuSearchResults.loading = loading;
    },
    setKuSearchResult(state, searchResults) {
      state.kuSearchResults = searchResults;
      state.kuSearchResults.loading = false;
    },
    setTextQuery(state, query) {
      state.filters.text = query;
    },
    setCurrentPage(state, page) {
      state.filters.page = page;
    },
    setSearchIndex(state, index) {
      state.filters.index = index;
    }
  },
  actions: {
    // AUTHENTICATION API REQUESTS
    checkAuthenticated({ commit }) {
      commit('setToken');
    },
    async login({ commit }, payload: { email: string, password: string },) {
      try {
        const res = await axios.post(`${API_URL}/login`, {email: payload.email, password: payload.password}, {withCredentials: true});
        commit('setToken');
      } catch (e) {
        commit('setError', 'There was a problem validating your credentials, please check the entered username and password')
      }
    },
    async logout({ commit }) {
      commit('deleteToken');
    },
    // GUIDELINES API REQUESTS
    async getGuidelineById({ commit }, payload: { id: string }) {
      try {
        const res = await axios.get(`${API_URL}/guidelines/${payload.id}`);
        commit('setGuideline', res.data);
      } catch (e) {
        commit('setError', 'There was an error getting the selected guideline, please try again later');
      }
    },
    async searchGuidelines({ state, commit }) {
      try {
        const query: any = {
          page: state.filters.page,
          limit: state.filters.limit,
          text: state.filters.text,
          index: state.filters.index,
          deprecated: state.filters.deprecated,
        };
        if (state.filters.frameworkNames.length > 0) {
          query.frameworks = state.filters.frameworkNames.toString();
        }
        commit('setGuidelineSearchLoading', true);
        let res;
        if(query.index !== 'ksaKus') {
          res = await axios.get(`${API_URL}/guidelines?${querystring.stringify(query)}`);
        } else {
          res = await axios.get(`${API_URL}/ksakus?${(querystring.stringify(query))}`);
        }
        commit('setGuidelineSearchResult', res.data);
      } catch (e) {
        commit('setError', 'There was an error searching guidelines / standards, please try again later');
      }
    },
    async deleteGuideline({ dispatch, commit, state }, payload: { id: string}) {
      try {
        await axios.delete(`${API_URL}/guidelines/${payload.id}`, state.token);
        await dispatch('searchGuidelines');
      } catch (e) {
        commit('setGuidelineSearchLoading', false);
        commit('setError', 'There was an error deleting the guideline, please try again later');
      }
    },
    async updateGuideline({ dispatch, commit, state }, payload: { id: string, updates: Partial<Guideline> }) {
      try {
        await axios.patch(`${API_URL}/guidelines/${payload.id}`, payload.updates, state.token);
        await dispatch('searchGuidelines');
      } catch (e) {
        commit('setError', 'There was an error updating the guideline, please try again later');
      }
    },
    async createGuideline({ dispatch, commit, state }, payload: { guideline: Guideline }) {
      try {
        await axios.post(`${API_URL}/guidelines`, payload.guideline, state.token);
        await dispatch('searchGuidelines');
      } catch (e) {
        commit('setError', 'There was a error creating a guideline, please try again later');
      }
    },
    // FRAMEWORKS API REQUESTS
    async searchFrameworks({ state, commit }) {
      try {
        const query = {
          text: state.filters.text,
          page: state.filters.page,
          limit: state.filters.limit,
          deprecated: 'all',
        };
        commit('setFrameworkSearchLoading', true);
        const res = await axios.get(`${API_URL}/frameworks?${querystring.stringify(query)}`);
        commit('setFrameworkSearchResult', res.data);
      } catch (e) {
        commit('setFrameworkSearchLoading', false);
        commit('setError', 'There was a problem searching frameworks, please try again later');
      }
    },
    async getFrameworkById({ commit }, payload: { id: string }) {
      try {
        const res = await axios.get(`${API_URL}/frameworks/${payload.id}`);
        commit('setFramework', res.data);
      } catch (e) {
        commit('setError', 'There was a problem getting the framework, please try again later');
      }
    },
    async createFramework({ dispatch, commit, state }, payload: { framework: Framework }) {
      try {
        await axios.post(`${API_URL}/frameworks`, payload.framework, state.token);
        await dispatch('searchFrameworks');
      } catch (e) {
        commit('setError', 'There was a problem creating a framework, please try again later');
      }
    },
    async deleteFramework({ dispatch, commit, state }, payload: { id: string }) {
      try {
        await axios.delete(`${API_URL}/frameworks/${payload.id}`, state.token);
        await dispatch('searchFrameworks');
      } catch (e) {
        commit('setError', 'There was a problem deleting the framework, please try again later');
      }
    },
    async deprecateFramework({ dispatch, commit, state}, payload: { id: string}) {
      try {
        await axios.patch(`${API_URL}/frameworks/${payload.id}/deprecate`, null, state.token);
        await dispatch('searchFrameworks');
      } catch (e) {
        commit('setError', 'There was a problem deprecating the framework, please try again later');
      }
    },

    async updateFramework({ dispatch, commit, state }, payload: { framework: Framework }) {
      try {
        await axios.patch(`${API_URL}/frameworks/${payload.framework._id}`, payload.framework, state.token);
        await dispatch('searchFrameworks');
      } catch (e) {
        commit('setError', 'There was a problem updating the framework, please try again later');
      }
    },

    //KU API Requests
    async searchKus({ state, commit }) {
      try {
        const query: {
          text: undefined,
          page: number,
          limit: number,
          ku: string
        } = {
          text: state.filters.text,
          page: state.filters.page,
          limit: state.filters.limit,
          ku: ''
        };
        if (state.filters.kus.length > 0) {
          query.ku = state.filters.kus.toString();
        }
        commit('setKuSearchLoading', true);
        const res = await axios.get(`${API_URL}/kus?${querystring.stringify(query)}`);
        commit('setKuSearchResult', res.data);
      } catch(e) {
        commit('setError', 'There was an error getting CAE Kus');
      }
    }
  },
})
