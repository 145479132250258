


























































import { Component, Vue } from 'vue-property-decorator';
import { Framework } from '@/types/Framework';
import PopupConfirm from '@/components/PopupConfirm.vue';
import Pagination from '@/components/Pagination.vue';
import UpdateFrameworkModal from '@/frameworks/components/UpdateFrameworkModal.vue';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    components: {
        PopupConfirm,
        UpdateFrameworkModal,
        Pagination
    },
})
export default class FrameworkList extends Vue {
    // Search stuff
    text = '';
    searchInput$ = new Subject<void>();

    // Delete popup
    deletePopup = false;
    selectedId = '';

    // Deprecate popup
    deprecatePopup = false;

    // Update popup
    updatePopup = false;
    selectedFramework: Framework = {
        name: '',
        author: '',
        source: '',
        description: '',
    };

    destroyed$: Subject<void> = new Subject();

    created(): void {
        this.$store.commit('setCurrentPage', 1);
        this.$store.commit('setTextQuery', undefined);
        this.searchInput$
            .pipe(debounceTime(650), takeUntil(this.destroyed$))
            .subscribe(async () => {
                this.$store.commit('setCurrentPage', 1);
                await this.searchFrameworks();
            });
    }

    mounted(): void {
        this.searchFrameworks();
    }

    destroyed(): void {
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
    }

    /**
     * Checks if the user is authenticated
     */
    get authenticated(): boolean {
        return this.$store.state.authenticated;
    }

    /**
     * Gets the frameworks from the search result
     */
    get frameworks(): Framework[] {
        return this.$store.state.frameworkSearchResult.results;
    }

    /**
     * Gets the total from the framework search result
     */
    get total(): number {
        return this.$store.state.frameworkSearchResult.total;
    }

    /**
     * Calculates the total number of pages given the total and the limit
     */
    get totalPages(): number {
        return Math.ceil(this.total / this.limit);
    }

    /**
     * Returns the limit of the query
     */
    get limit(): number {
        return this.$store.state.filters.limit;
    }

    /**
     * Sends a notification to the search input to
     * delay the time of search on
     */
    searchChanged(): void {
        this.searchInput$.next();
    }

    /**
     * Changes the page
     */
    async changePage(): Promise<void> {
        await this.searchFrameworks();
    }

    /**
     * Searches for frameworks
     */
    async searchFrameworks(): Promise<void> {
        this.$store.commit('setTextQuery', this.text);
        await this.$store.dispatch('searchFrameworks');
    }

    /**
     * Opens the delete framework confirm modal
     * 
     * @param id the framework id to delete
     */
    deleteFramework(id: string): void {
        this.selectedId = id;
        this.deletePopup = true;
    }

    /**
     * Confirms whether the user would like to delete the framework or not
     * 
     * @param confirmed whether the user would like to delete the framework or not
     */
    async confirmDelete(confirmed: boolean): Promise<void> {
        if (confirmed) {
            await this.$store.dispatch('deleteFramework', { id: this.selectedId });
        }
        this.deletePopup = false;
        this.selectedId = '';
    }

      /**
     * Opens the deprecate framework confirm modal
     * 
     * @param id the framework id to deprecate
     */
     deprecateFramework(id: string): void {
        this.selectedId = id;
        this.deprecatePopup = true;
    }

    /**
     * Confirms whether the user would like to deprecate the framework or not
     * 
     * @param confirmed whether the user would like to deprecate the framework or not
     */
     async confirmDeprecate(confirmed: boolean): Promise<void> {
        if (confirmed) {
            await this.$store.dispatch('deprecateFramework', { id: this.selectedId });
        }
        this.deprecatePopup = false;
        this.selectedId = '';
    }

    /**
     * Opens the update framework modal
     * 
     * @param f the framework to update
     */
    updateFramework(f: Framework): void {
        this.updatePopup = true;

        // Create a deep copy
        const framework = JSON.parse(JSON.stringify(f));
        this.selectedFramework = framework;
    }

    /**
     * Confirms that the framework has been udpated
     * 
     * @param framework the newly updated framework object
     */
    async confirmUpdate(framework: Framework): Promise<void> {
        if (framework && framework !== this.selectedFramework) {
            await this.$store.dispatch('updateFramework', { framework });
        }
        this.updatePopup = false;
        this.selectedFramework = {
            name: '',
            author: '',
            source: '',
            description: '',
        };
    }
}
